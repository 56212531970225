<template>
  <v-row class="newPasswordContainer">
    <v-col
      md="12"
      sm="12"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div class="formDiv">
        <v-form>
          <v-container>
            <v-row>
              <v-col
                style="
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <v-img
                  :src="require('@/assets/general/logoLogin.png')"
                  width="120"
                  height="110"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span
                  class="textDecorationBold"
                  style="font-size: 38px; color: #263573"
                  ><b>{{ $t("newPasswordTitle") }}</b></span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col
                ><span
                  class="textFontDecoration"
                  style="font-size: 18px; color: #343a40"
                  >{{ $t("newPasswordSubtitle") }}</span
                >
              </v-col>
            </v-row>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="boxContainer mt-5">
                <v-row>
                  <v-col align="left">
                    <v-icon>mdi-email-outline</v-icon>
                    <b style="color: #263573"> email_user@newdeal.gr</b>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      prepend-inner-icon="mdi-lock-outline"
                      type="password"
                      hide-details="auto"
                      :error-messages="passwordErrors"
                      v-model="password"
                      :label="$t('newPassword') + '*'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      prepend-inner-icon="mdi-lock-outline"
                      type="password"
                      hide-details="auto"
                      :error-messages="confirmPassErrors"
                      v-model="confirmPass"
                      :label="$t('confNewPass') + '*'"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      elevation="5"
                      :loading="loading"
                      @click="onSetNewPasswordHandler"
                      class="mr-4 textFontDecoration btnContainerFp"
                    >
                      {{ $t("submit") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>

/* 
  Name:NewPassword
  Description: NewPassword view is a page that user enters two times the new password for validation.
*/
import { required, sameAs } from "vuelidate/lib/validators";
import firebase from "firebase";
export default {
  name: "NewPassword",
  created() {
    const component = this;
    this.handler = function (e) {
      component.$emit("keydown", e);
    };
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter") this.onSetNewPasswordHandler();
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", (e) => {
      if (e.key === "Enter") this.onSetNewPasswordHandler();
    });
  },
  data() {
    return {
      loading: false,
      password: null,
      confirmPass: null,
      oobCode: null,
    };
  },

  validations: {
    password: {
      required,
    },
    confirmPass: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push(this.$t("requiredField"));
      return errors;
    },
    confirmPassErrors() {
      const errors = [];
      if (!this.$v.confirmPass.$dirty) return errors;
      if (!this.$v.confirmPass.required) errors.push(this.$t("requiredField"));
      if (!this.$v.confirmPass.sameAsPassword)
        errors.push("Δεν ταιριάζει με τον κωδικό");
      return errors;
    },
  },
  methods: {
    async onSetNewPasswordHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      const oobCode = this.getParameterByName("oobCode");
      const password = this.password;
      let self = this;

      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then(function () {
          firebase
            .auth()
            .confirmPasswordReset(oobCode, password)
            .then(function () {
              self.$router.push("/newPasswordSuccess");
            })
            .catch(function (error) {
              console.error("error 1" + error);
            });
        })
        .catch(function (error) {
          console.error("oobCode is invalid" + error);
        });
      this.loading = false;
    },
    getParameterByName(name) {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href);
      if (results == null) return "";
      else return decodeURIComponent(results[1].replace(/\+/g, " "));
    },
  },
};
</script>

<style scoped>
.newPasswordContainer {
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(230, 235, 247, 1) 100%
  );
}
.btnContainerFp {
  text-transform: capitalize;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  width: 100%;
}

.formDiv {
  width: 40%;
}
.boxContainer {
  width: 70%;
}

@media screen and (max-width: 959px) {
  .formDiv {
    width: 100%;
  }
}
</style>
